import React, { useState } from 'react';
import { navigate } from 'gatsby';
import './index.scss';
import Video from '../components/video';
import InfiniteScroll from 'react-infinite-scroll-component';
import ComingSoon from '../components/coming-soon';
import { FeedbackCard } from '../components/extra-components';


const Feedback = ({ title, data = {} }) => {

  const [ items, setItems ] = useState(Array.from({ length: 20 }));
  const [ hasMore, setHasMore ] = useState(true);

  const feedbackList = data.feedback || [];

  const fetchMoreData = () => {
    if (items.length >= 500) {
      setHasMore(false);
      return;
    }
    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      setItems(items.concat(Array.from({ length: 20 })));
    }, 500);

    fetch('https://jsonplaceholder.typicode.com/todos/1')
      .then(response => response.json())
      .then(json => console.log(json));

  };

  return (
    <div className='sa-feedback-root'>
      <h4 className='sa-feedback-main-title'>{title}</h4>
      <div className='sa-feedback-container container'>
        {/*<div className='sa-feedback-filters'>*/}
        {/*  <select className='form-select' defaultValue={'0'}>*/}
        {/*    <option value="0">Most Recent</option>*/}
        {/*    <option value="1">Filter Two</option>*/}
        {/*    <option value="2">Filter Three</option>*/}
        {/*    <option value="3">Filter Four</option>*/}
        {/*  </select>*/}
        {/*</div>*/}

        {/*<ComingSoon autoHeight />*/}

        <InfiniteScroll
          className='sa-feedback-infinite-scroll-container row gx-5'
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          height={'auto'}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {feedbackList.map((item, index) => (
            <FeedbackCard key={index} item={item} navigate={navigate} />
          ))}
        </InfiniteScroll>
        <div style={{ height: 10 }} />
      </div>
    </div>
  );
};

export default Feedback;
